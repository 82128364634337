import Form from '../components/form';

const Contestant = () => {

  return (
    <div className='App'>
      <div className='App-header bg-current text-secondary'>
        <Form id={'contestant'} contestant />
      </div>
    </div>
  );
}

export default Contestant;
